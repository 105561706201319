import React from "react";
import { graphql } from "gatsby";
import BlogComponent from "@lekoarts/gatsby-theme-minimal-blog/src/components/blog";

const Blog = ({ data }) => <BlogComponent posts={data.allPost.nodes} />;

export default Blog;

export const query = graphql`
  query ($formatString: String!) {
    allPost(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        excerpt
        timeToRead
        description
        banner {
          childImageSharp {
            resize(width: 1200, quality: 90) {
              src
            }
          }
        }
        tags {
          name
          slug
        }
      }
    }
  }
`;
